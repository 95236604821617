body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif, src("");
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*::-webkit-scrollbar-track
{
    background-color: #c2b0b0;

}

*::-webkit-scrollbar
{
    width: 10px;
    height: 10px;
    background-color: #F5F5F5;
}

*::-webkit-scrollbar-thumb
{
    /* mimics textColorPrimary.primary in src/globalThemeVariables.ts, */
    background-color: #6b5a5a;
    border: 2px solid #555555;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

main
{
  width: 100%
}

section
{
  width: 100%;
  overflow-x: hidden;
}

.App
{
  width: 100%;
  max-width: 170rem;
  display: flex;
  margin: auto;
}

.basicPageStyling
{
  background-color: #fafafa;
  width: 100%;
  margin-bottom: -3.125rem !important;
}

.routeDivContainer
{
  width : 100%;
}

p.pageText
{
    font-family: "Raleway";
    font-size: 1.1rem;
    letter-spacing: 2px;
    text-align: left;
}

@media screen and (min-width: 600px)
{
  p.pageText:not(li > p.pageText)
  {
    text-align: justify;
  }
}

p.textAlignCenter
{
  text-align: center;
}