.loaderBg
{
    background-color: #fff;
    height:             100vh;
    width:              100vw;
    display:            flex;
    flex-direction:     column;
    justify-content:    center;
    align-items:        center;
}

.loaderBg > img
{
    position: absolute;
    top: 3.25rem;
}

.loaderBg > h1
{
    font-family:    'montserrat','Montserrat-Alternates','ubuntu';
    font-size:      2.5rem;
    font-weight:    300;
    color:          #c66961;
    letter-spacing: 4px;
    margin-top:     4rem;
    text-transform: uppercase;
}

 /* Credit Stephan Wagner, https://stephanwagner.me/only-css-loading-spinner */

@keyframes spinner
{
    to {transform: rotate(360deg);}
}
   
.spinner:before
{
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 35%;
    left: calc(50% - 25px);
    width:  80px;
    height: 80px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 5px solid transparent;
    border-top-color: #FFCF00;
    border-bottom-color: #EE6123;
    animation: spinner .8s cubic-bezier(0.305, 0.170, 0.245, 0.800) infinite;
    /* margin-top: -15px; */
    /* margin-left: -15px; */
    /* border-radius: 50%; */
    /* border: 1px solid #005; */
    /* border-top-color: #f99c94; */
    /* animation: spinner 1s linear infinite; */
}

.spinnerComp:before
{
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: calc(50% - 5px);
    width:  30px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid transparent;
    border-top-color: #FFCF00;
    border-bottom-color: #EE6123;
    animation: spinner .8s cubic-bezier(0.305, 0.170, 0.245, 0.800) infinite;
}